<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>消息管理</el-breadcrumb-item>
      <el-breadcrumb-item>APP推送</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="addNotice('new', '')" size="small">创建模板</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column prop="text" label="内容">
        <template slot-scope="scope">
          <span>{{ filter(scope.row.text) }}</span>
        </template>
      </el-table-column>
      <el-table-column width="300">
        <template slot-scope="scope">
          <div class="opt">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="addNotice('bj', scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="del(scope.row, scope.$index)"
              >删除</el-button
            >
            <p></p>
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-warning"
              @click="send('1', scope.row)"
              >推送所有用户</el-button
            >
            <!-- <el-button
              size="mini"
              type="success"
              icon="el-icon-circle-check"
              @click="send('2', scope.row)"
              >推送已分配用户</el-button
            > -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" :visible.sync="dialogFormVisible">
      <el-form class="hdtc" label-width="170px">
        <el-form-item label="标题">
          <el-input
            type="text"
            style="float: left;"
            v-model="msgtitle"
            autocomplete="off"
            placeholder="请输入消息内容"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input
            type="textarea"
            style="float: left;"
            v-model="content"
            autocomplete="off"
            placeholder="请输入消息内容"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="开启地址" style="text-align:left;">
          <el-radio-group v-model="totype">
            <el-radio :label="1">首页</el-radio>
            <el-radio :label="2">拍卖页</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogFormVisible = false;
            content = '';
            msgtitle = '';
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  inject: ["reload"],
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      dialogFormVisible: false,
      type: "",
      item: "",
      title: "添加推送",
      msgtitle: "",
      content: "",
      totype: "",
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.axios
        .get("/getui/get_gu_getui_template")
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
    addNotice(type, item) {
      this.dialogFormVisible = true;
      this.type = type;
      this.item = item;
      if (type != "new") {
        this.title = "编辑推送";
        this.axios
          .get("/getui/get_gu_getui_template_by_id?id=" + item.id)
          .then((res) => {
            if (res.data.code == 0) {
              var msg = JSON.parse(res.data.result.text);
              this.msgtitle = msg.title;
              this.content = msg.message;
              this.totype = msg.type;
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.title = "添加推送";
        this.msgtitle = "";
        this.content = "";
        this.totype = 1;
      }
    },
    del(row, index) {
      this.$confirm("此操作将永久删除该推送, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/getui/delete_gu_getui_template", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    sub() {
      let url = "";
      let str = "";
      let form = {
        title: this.msgtitle,
        text: JSON.stringify({ title: this.msgtitle, message: this.content, type: this.totype }),
        desc: "",
      };
      if (this.type == "new") {
        url = "/getui/insert_gu_getui_template";
        str = "添加成功";
      } else {
        url = "/getui/update_gu_getui_template";
        str = "修改成功";
        form.id = this.item.id;
      }
      this.axios
        .post(url, this.qs.stringify(form))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.dialogFormVisible = false;
            this.$message({
              message: str,
              type: "success",
            });
            this.reload();
          } else {
            this.$message(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filter(val) {
      return JSON.parse(val).message;
    },
    send(type, row) {
      //1 所有患者，2带医生
      //发送给所有人
      let form = {
        type: type,
        title: row.title,
        text: JSON.stringify({ title: maa.title, message: maa.message, type: maa.type }),
      };
      this.axios
        .post("/getui/push_getui", this.qs.stringify(form))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
          } else {
            this.$message(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //发送给个人
      // var maa = JSON.parse(row.text);
      // let form = {
      //   title: row.title,
      //   text: JSON.stringify({ title: maa.title, message: maa.message, type: maa.type }),
      //   cid: "9147dca7e5098b291d2dc9c5fdebe36a",
      // };
      // console.log(form)
      // this.axios.post("/getui/push_getui_to_single", this.qs.stringify(form)).then((res) => {
      //   if (res.data.code == 0) {
      //     this.$message({
      //       message: "发送成功",
      //       type: "success",
      //     });
      //   } else {
      //     this.$message({
      //       message: res.data.msg,
      //       type: "error",
      //     });
      //   }
      // });
    },
  },
};
</script>
<style>
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.hdtc {
  width: 80%;
}

.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>
